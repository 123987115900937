import React from 'react';
import Svg from './svg';
import { withProps } from 'recompose';

export default withProps({
  viewBox: '0 0 20 20',
  children: (
    <path d="M13.4 2h1.2a3.7 3.7 0 0 1 1.2.5 5.1 5.1 0 0 1 .9.6h.5a7 7 0 0 0 1.5-.5 9.3 9.3 0 0 0 .6-.2h.1v-.1.2l-.2.4a4.5 4.5 0 0 1-1.3 1.5h-.1v.1h.5a9 9 0 0 0 1-.3l.5-.2h.2a79.3 79.3 0 0 1-1.7 1.8c-.2.1-.3.4-.3.6a15.8 15.8 0 0 1-.5 3.4 12 12 0 0 1-5.1 6.8 20.8 20.8 0 0 1-1.7.8 13.7 13.7 0 0 1-3.2.8H5.6a8.2 8.2 0 0 1-1 0 10.8 10.8 0 0 1-4.3-1.6l-.2-.1H0v-.1h.1a11 11 0 0 0 2 0A9.3 9.3 0 0 0 5.6 15l.4-.3H6l-.1-.1a67.6 67.6 0 0 1-1.3-.3 4.8 4.8 0 0 1-1.2-.7 8 8 0 0 1-.7-.9l-.3-.5-.2-.4H3.2a4.8 4.8 0 0 0 .8 0l.1-.1h-.1a3.3 3.3 0 0 1-.7-.3 3.7 3.7 0 0 1-2-1.9 4.3 4.3 0 0 1-.4-1.5v-.4l.4.2.7.2h.7-.1a22.5 22.5 0 0 1-.5-.4 10.6 10.6 0 0 1-.6-.8 5.1 5.1 0 0 1-.5-1.3 4 4 0 0 1 .3-2.5l.1-.3h.1l.3.3a16 16 0 0 0 2.2 2 13 13 0 0 0 3.4 1.5 11.5 11.5 0 0 0 2.4.4h.1v-.3a4.2 4.2 0 0 1 0-1.3 4.4 4.4 0 0 1 .8-1.8 3.9 3.9 0 0 1 1.2-1 4.7 4.7 0 0 1 1.6-.6z" />
  ),
})(Svg);
